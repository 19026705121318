.AddUnit {
  padding-top: 24px;
  padding-bottom: 24px;
  .form-row {
    display: flex;
    flex-wrap: wrap;
    .input-element {
      // margin: 1.4rem 0;
      width: 23%;
      margin: 4px 10px;

      input {
        // height: 2.8rem;
        // background-color: rgb(237, 237, 237) !important;
      }
    }
    .ant-upload {
      width: 100% !important;
    }
    .ant-upload-select {
      // width: 102px !important;
      border: none !important;
      background: none !important;
      height: auto !important;
    }
    .PlanDetailList {
      display: flex;
      flex-wrap: wrap;
      .PlanInstCard {
        padding: 8px 12px;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        margin-right: 12px;
        .PlanInstCardRight {
          margin-left: 34px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
  }

  .FormAction {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
}
