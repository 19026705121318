body {
  background: rgb(204, 204, 204);
}
page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  //   box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  padding: 20px;
  border: 4px solid black;
  .topheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .propertydetails {
    margin-top: 20px;

    .tabledetails {
      margin-top: 14px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgb(209, 208, 208);
    }
  }
  .paymentdetails {
    margin-top: 20px;
    .emidetails {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .boxcard {
        padding: 20px;
        border-radius: 4px;
        background-color: black;
        color: white;
        width: 20%;
        margin-right: 14px;
        box-sizing: border-box;
        height: 150px;
      }
    }
  }
}
page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
}

@media print {
  body,
  page {
    background: white;
    margin: 0;
    box-shadow: 0;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  background: black;
  text-align: left;
  padding: 8px;
  color: white;
}
td {
  padding: 2px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

@media (min-width: 320px) {
  .emidetails {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .boxcard {
      width: 45% !important;
      margin-bottom: 24px !important;
      height: auto !important;
      h2 {
        font-size: 16px !important;
      }
    }
  }
  .note {
    font-size: 12px;
  }
  th {
    background: black;
    text-align: left;
    padding: 2px;
    font-size: 10px;
    color: white;
  }
  td {
    font-size: 11px;
  }
  h4 {
    font-size: 12px;
  }
  .tabledetails {
    h4 {
      font-size: 12px !important;
    }
  }
}
@media (min-width: 480px) {
  .emidetails {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .boxcard {
      width: 45% !important;
      margin-bottom: 24px !important;
      height: auto !important;
      h2 {
        font-size: 16px !important;
      }
    }
  }
  .note {
    font-size: 12px;
  }
  th {
    background: black;
    text-align: left;
    padding: 2px;
    font-size: 10px;
    color: white;
  }
  td {
    font-size: 11px;
  }
  h4 {
    font-size: 12px;
  }
  .tabledetails {
    h4 {
      font-size: 12px !important;
    }
  }
}
