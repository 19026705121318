.PropertyCard {
  width: 268px;
  height: 268px;
  border-radius: 16px;
  background-color: white;
  padding: 10px;
  cursor: pointer;
  box-sizing: border-box;
  .CardImage {
    position: relative;
    img {
      border-radius: 12px;
      width: 100%;
      height: 148px;
      object-fit: cover;
      opacity: 0.9;
    }
    .floatTopButton {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .floatingBottomButton {
      position: absolute;
      bottom: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      left: 10px;
    }
  }
  .CardDetails {
    .Amentities {
      display: flex;
      align-items: center;
      margin-top: 16px;
      .Amentity {
        display: flex;
        align-items: center;
        margin-right: 18px;
      }
    }
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    transition: all 0.3s;
  }
}

@media only screen and (max-width: 768px) {
  //tablet screen
  .PropertyCard {
    width: 100% !important;
  }
}

@media only screen and (max-width: 600px) {
  //mobile screen

  .PropertyCard {
    width: 100% !important;
    height: 284px;
  }
}
