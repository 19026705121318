.PromoterList {
  height: 100%;
  display: flex;
  flex-direction: column;
  .TopSelectors {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .leftSelectors {
      display: flex;
      align-items: center;
    }
    .rightSelectors {
      display: flex;
    }
    .WidgetContainer {
      min-width: 360px;
      margin-right: 24px;
    }
  }
  #tabBarContainer {
    margin-top: 24px;
  }

  @media only screen and (max-width: 768px) {
    //tablet screen

    .TopSelectors {
      flex-direction: column;
      .leftSelectors {
        order: 2;
        flex-direction: column;
        margin-top: 12px;
      }
      .rightSelectors {
        margin-top: 24px !important;

        width: 100% !important;
        order: 1;
        justify-content: space-between;
        .WidgetContainer {
          width: 45% !important;
        }
      }
    }

    table {
      background-color: white !important;
    }
    #tabBarContainer {
      overflow-x: auto;
      margin-top: 24px;
    }
    .ant-tabs-nav-wrap {
      overflow: auto !important;
    }
  }

  @media only screen and (max-width: 600px) {
    //mobile screen

    .TopSelectors {
      flex-direction: column !important;
      .leftSelectors {
        flex-direction: column;
        .WidgetContainer {
          margin-right: 0;
          margin-bottom: 14px;
        }
      }
      .WidgetContainer {
        width: 100%;
        margin-right: 0;
      }
    }
    table {
      background-color: white !important;
    }
    #tabBarContainer {
      overflow-x: auto;
      margin-top: 24px;
    }
    .ant-tabs-nav-wrap {
      overflow: auto !important;
    }
  }

  //ant modifiers

  .ant-table-thead .ant-table-cell {
    background-color: #1d5d9b !important;
    color: white !important;
    svg {
      color: white !important;
    }
  }
  .ant-table-tbody .ant-table-cell {
    color: #7c8091 !important;
  }
  .ant-tabs-nav-wrap {
    justify-content: flex-start !important;
    .ant-tabs-tab {
      // min-width: 50px !important;
      // justify-content: center !important;
      margin-left: 100px !important;
      &:first-child {
        margin-left: 50px !important;
      }
    }
  }
}
