.MakeAnnouncement{
    .form-row{
        display: flex;
    }
    .action-buttons{
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
    }

}


.public-DraftEditor-content {
    box-sizing: border-box !important;
}