.PropertyDetail {
  flex: 1;
  .Images {
    margin-bottom: 24px;
  }
  .CardDetails {
    .Amentities {
      display: flex;
      align-items: center;
      margin-top: 16px;
      .Amentity {
        display: flex;
        align-items: center;
        margin-right: 18px;
      }
    }
  }
  .PaymentPlan {
    background: var(--base-color-gray-light);
    padding: 12px;
    box-sizing: border-box;
    border-radius: 16px;
    margin-top: 24px;
    .cardContainer {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
      .CardHeader {
        background-color: var(--base-color-black);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px;
        box-sizing: border-box;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding-left: 12px;
        padding-right: 12px;
      }
      .CardBody {
        background-color: white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-left: 12px;
        padding-right: 12px;
        padding: 6px;
        box-sizing: border-box;
        .InfoRow {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
  .DocumentList {
    margin-top: 24px;
    .Documents {
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      .DocumentCard {
        width: 100%;
        height: 44px;
        border-radius: 12px;
        background: var(--base-color-gray-light);
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        cursor: pointer;
        padding: 0 16px;
        box-sizing: border-box;
        &:hover {
          transition: all 0.2s ease-out, transform 0.2s ease-out;

          transform: scale(1.03);
          svg {
            color: var(--base-color-red) !important;
          }
        }
      }
    }
  }
}
