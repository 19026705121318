.PropertyDetails {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .DetailColumnOne {
    width: 70%;

    box-sizing: border-box;
    .PropertyInfoContainer {
      border-radius: 8px;
      background-color: white;
      padding: 16px;
      padding-bottom: 24px;
      .PropertyImages {
        width: 100%;
        display: flex;
        align-items: flex-start;

        .CarasoulContainer {
          width: 70%;
        }
        .MoreImages {
          margin-left: 12px;
          width: 30%;
          cursor: pointer;

          .PropertyImageLast {
            height: 120px;
            width: 100%;
            margin-bottom: 12px;
            position: relative;
            img {
              border-radius: 10px;
              width: 100%;
              height: 100%;
              object-fit: cover;
              opacity: 0.9;
            }
            .imageCount {
              position: absolute;
              left: 50%;
              top: 40%;
              transform: translate(-50%, 0);
              margin-left: auto;
              margin-right: auto;
              width: 100px;
              background-color: white;
              border-radius: 4px;
              padding: 2px 4px;
              opacity: 0.9;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .BasicPropertyInfo {
        .Amentities {
          display: flex;
          align-items: center;
          margin-top: 16px;
          .Amentity {
            display: flex;
            align-items: center;
            margin-right: 18px;
          }
        }
      }
    }
    .MoreInfoContainer {
      border-radius: 8px;
      background-color: white;
      padding: 16px;
      margin-top: 24px;
      .InfoList {
        margin-top: 24px;
        .InfoListItem {
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          .InfoListItemTitle {
            width: 20%;
          }
        }
      }
    }
    .AmentityContainer {
      border-radius: 8px;
      background-color: white;
      padding: 16px;
      margin-top: 24px;
      .AmentitiyList {
        margin-top: 24px;
        display: flex;
        .Amentity {
          display: flex;
          align-items: center;
          background-color: #e3e7ec;
          padding: 4px 6px;
          border-radius: 4px;
          margin-right: 16px;
        }
      }
    }
  }
  .DetailColumnTwo {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    padding-left: 16px;
    flex-direction: column;
    .ReferDetail {
      background-color: white;
      border-radius: 8px;
      padding: 12px;
      box-sizing: border-box;
      width: 100%;
      .UserInfo {
        margin-top: 24px;
        display: flex;
        align-items: center;
        .ReferalInfo {
          flex: 1;
          margin-left: 10px;
          .nameInfo {
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .MapBoxContainer {
      margin-top: 24px;
      width: 100%;
      height: 260px;
    }

    .PropertyDocumentCard {
      background-color: white;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 14px;
      margin-top: 24px;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 768px) {
    //tablet screen
    flex-direction: column !important;
    .DetailColumnOne {
      width: 100% !important;
      .PropertyInfoContainer {
        .PropertyImages {
          .CarasoulContainer {
            width: 100%;
          }
          .MoreImages {
            display: none !important;
          }
        }
      }
      .MoreInfoContainer {
        .InfoList {
          .InfoListItem {
            width: 100% !important;
            .InfoListItemTitle {
              width: 50%;
            }
          }
        }
      }
    }
    .DetailColumnTwo {
      margin-top: 24px !important;
      padding-left: 0 !important;
      width: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    //mobile screen
    flex-direction: column !important;
    .DetailColumnOne {
      width: 100% !important;
      .PropertyInfoContainer {
        .PropertyImages {
          .CarasoulContainer {
            width: 100%;
          }
          .MoreImages {
            display: none !important;
          }
        }
      }
      .MoreInfoContainer {
        .InfoList {
          .InfoListItem {
            width: 100% !important;
            .InfoListItemTitle {
              width: 50%;
            }
          }
        }
      }
    }
    .DetailColumnTwo {
      margin-top: 24px !important;
      padding-left: 0 !important;
      width: 100%;
    }
  }
}
