.ProjectList {
  .InvestorDetail {
    .InvestorBasicInfo {
      display: flex;
      flex-wrap: wrap;
      margin-top: 28px;
      .InfoGroup {
        margin-right: 24px;
        margin-bottom: 24px;
      }
    }
    .TableContainer {
      .TableTopAction {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
