.OTPBox {
  display: flex;
  align-items: center;
  justify-content: center;
  textarea:focus,
  input:focus {
    outline: none;
  }
  .otp-input {
    width: 52px;
    height: 52px;
    border-radius: 8px;
    margin-right: 8px;
    position: relative;
    padding: 14px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: #f4f6f9;
    border: none;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    // .horizontal-line {
    //   background: #6a6a6a;
    //   border-width: 1px;
    //   width: 100%;
    //   height: 2px;
    //   border-radius: 4px;
    //   z-index: 1;
    // }
    // input {
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   left: 0;
    //   bottom: 0;
    //   border: none;
    //   font-size: 16px;
    //   text-align: center;
    //   font-weight: 500;
    // }
  }

  @media only screen and (max-width: 768px) {
    //tablet screen

    @media only screen and (max-width: 600px) {
      //mobile screen
      .otp-input {
        width: 38px;
        height: 38px;
      }
    }
  }
}
