.Dashboard {
  .greeting {
  }
  .TopContainer {
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
    margin-top: 24px;
    .WidgetCard {
      background: white;
      border-radius: 14px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      width: 19%;
      padding: 20px 24px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .WidgetContainer {
    display: flex;
    // flex-direction: row;
    margin-top: 24px;
    .WidgetColumn {
      display: flex;
      //   padding-right: 30px;
      flex-wrap: wrap;
      //   flex: 1;
      .widgetCard {
        background-color: white;
        border-radius: 16px;
        min-width: 142px;
        min-height: 192px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        margin: 0 24px 24px 0px;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 102px;
          opacity: 0.7;
        }
        &:hover {
          transition: all 0.2s ease-out, transform 0.2s ease-out;

          transform: scale(1.03);
        }
      }
    }
    .WidgetColumn:last-child {
      padding-right: 0;
      //   padding-left: 30px;
    }
  }
}

@media only screen and (max-width: 768px) {
  //tablet
  .greeting {
    margin-top: 24px !important;
  }
  .WidgetContainer {
    flex-direction: column;
    .WidgetColumn {
      width: 100% !important;
      .widgetCard:first-child {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .WidgetContainer {
    flex-direction: column;
    .WidgetColumn {
      width: 100% !important;
      .widgetCard {
        width: 100% !important;
        margin-right: 0 !important;
      }
    }
  }
}

/* From Uiverse.io by gharsh11032000 */
.card {
  min-width: 142px;
  min-height: 130px;
  background-color: #4158d0;
  background-image: linear-gradient(
    135deg,
    #8bc6ec 0%,
    #9599e2 100%,
    // #ffcc70 100%
  );
  border-radius: 8px;
  color: white;
  overflow: hidden;
  position: relative;
  transform-style: preserve-3d;
  perspective: 1000px;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  cursor: pointer;
}

.card-content {
  padding: 20px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.card-content .card-title {
  font-size: 24px;
  font-weight: 700;
  color: inherit;
  text-transform: uppercase;
}

.card-content .card-para {
  color: inherit;
  opacity: 0.8;
  font-size: 14px;
}

.card:hover {
  transform: rotateY(10deg) rotateX(10deg) scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.1));
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 1;
}

.card:hover:before {
  transform: translateX(-100%);
}

.card:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.1));
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 1;
}

.card:hover:after {
  transform: translateX(100%);
}
