.CreateReport {
  .form-row {
    display: flex;
  }
  .action-buttons {
    margin-top: 24px;
  }
}

.public-DraftEditor-content {
  box-sizing: border-box !important;
}
