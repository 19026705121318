.masterLayout {
  // background-color: #1D5D9B;

  .leftSideNav {
    padding-top: 0;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 96%;
    // background: linear-gradient(90deg, #ffefef 0%, #eef5ff 100%);
    .LogoMenuContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      .appLogoContainer {
        padding: 20px;
        display: flex;
        justify-content: center;
        // padding-top: 60px;
        // padding-bottom: 40px;
      }
      .userInfoContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 44px;
        margin-top: 8px;
        .profileImage {
          width: 102px;
          height: 102px;
          object-fit: cover;
          border-radius: 50%;
        }
        .userInfo {
          margin-top: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      .menuContainer {
        flex: 1;
        display: flex;
        flex: 1 1 auto;
        overflow-y: auto;
        height: 0px;
      }
    }

    .logout {
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-right: 40px;
      padding-top: 40px;
      cursor: pointer;
    }
  }

  //ant design modifiers
  .ant-menu-root {
    border-inline-end: 0 !important;
    .ant-menu-item,
    .ant-menu-submenu-title {
      width: 100% !important;
      margin: 0 !important;
    }
  }
  .ant-menu-item-selected {
    background: linear-gradient(
      90deg,
      #8bc6ec 0%,
      rgba(49, 80, 206, 0.00204873) 100%
    ) !important;
    border-left: 5px solid #001f3f;
  }

  background-color: #eef5ff;

  // @media screen and (min-width: 1280px) {
  //   //For Big TV's (HD Screens)
  //   .ant-layout-content {
  //     padding-left: 15%;
  //   }
  // }
}

// custom scrollbar
#style-4::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 44, 7, 0.3);
  background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar-thumb {
  background-color: #4b6bc2;
  // border: 2px solid #555555;
}
