.LeadDetails {
  .LeadDetailContainer {
    display: flex;
    margin-top: 48px;
    align-items: flex-start;
    justify-content: space-between;
    .InfoColumn {
      width: 25%;
      .WidgetContainer {
        background-color: white;
        border-radius: 16px;
        padding: 20px;
        box-sizing: border-box;
        margin-bottom: 28px;
        .WidgetHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .WidgetListItem {
          margin-top: 28px;
          .ListItem {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
          }
        }
        .WidgetContent {
          margin-top: 24px;
          .ActivityItemList {
            margin-top: 10px;
            display: flex;
            align-items: center;
            .ActivityItem {
              display: flex;
              align-items: center;
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
  .ant-upload-list {
    display: none !important;
  }
}
