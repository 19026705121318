.CreateProject {
  .FormSection {
    margin-bottom: 24px;
    padding: 20px;
    background: white;
    border-radius: 12px;
    box-sizing: border-box;
    .Title {
      border-bottom: 1px solid rgb(231, 230, 230);
      margin-bottom: 24px;
    }
    .form-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .input-element {
        // margin: 1.4rem 0;
        width: 30%;

        input {
          // height: 2.8rem;
        }
      }
      .ant-upload {
        width: 100% !important;
      }
      .ant-upload-select {
        // width: 102px !important;
        border: none !important;
        background: none !important;
        height: auto !important;
      }
      .PlanDetailList {
        display: flex;
        flex-wrap: wrap;
        .PlanInstCard {
          padding: 8px 12px;
          box-sizing: border-box;
          border-radius: 8px;
          background-color: white;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;
          margin-right: 12px;
          .PlanInstCardRight {
            margin-left: 34px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
        }
      }
      .full-row-element {
        width: 100%;
        //   background: white;
        .ItemContainer {
          width: 100%;
          flex-wrap: wrap;
          display: flex;
          .Item {
            width: 14%;
          }
          .CardListInfo {
            padding: 8px;
            background: white;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          }
        }
      }
      .ItemVerticleContainer {
        width: 100%;
        .ItemContainer {
          display: flex;
          align-items: center;
          width: 100%;
          .ItemWidget {
            width: 25%;
            margin-right: 24px;
          }
          .CardListContainer {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            .CardListInfo {
              padding: 8px;
              background: white;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            }
          }
        }
      }
    }
  }
}
