.BrokerProfile {
  width: 100%;
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  .TopHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .UserDetails {
    margin-top: 18px;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;

    .UserInfoGroup {
      width: 24%;
      margin-bottom: 24px;
    }
  }
}
