.Settings {
  display: flex;
  .SettingDrawer {
    width: 300px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;
    border-radius: 6px;
    box-sizing: border-box;
    height: auto;
    .MenuList {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      .MenuItem {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 12px 20px;

        &:hover {
          background-color: rgb(236, 234, 234);
          transition: all 0.4s;
        }
      }
      .--selected {
        background-color: rgb(236, 234, 234);
      }
    }
  }
  .SettingContent {
    flex: 1;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;
    border-radius: 6px;
    min-height: 400px;
    margin-left: 44px;
    display: flex;
    flex-direction: column;
    .ContentHeader {
      padding: 20px 30px;
    }
    .ContentBody {
      flex: 1;
      padding: 20px;
      box-sizing: border-box;
      .FormGroup {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;
        padding: 0 10px;
        .FormElement {
          width: 30%;
        }
      }
    }
  }
}
