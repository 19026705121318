.LeavesDashboard {
  height: 100%;
  display: flex;
  flex-direction: column;
  .TopGistContainer {
    display: flex;
    align-content: center;
    .GistCard {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      border-radius: 18px;
      background-color: white;
      width: 20%;
      padding: 20px;
      box-sizing: border-box;
      height: 120px;
      display: flex;
      align-items: center;
      margin-right: 44px;
      .NumContainer {
        width: 72px;
        height: 72px;
        background-color: #108ee91a;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .--yellow {
        background-color: #f39c1226;
      }
      .--red {
        background-color: #ff00001a;
      }
    }
  }
  .AbsentInfoCard {
    margin-top: 28px;
    padding: 14px 20px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 16px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .LeftCard {
      display: flex;
      align-items: center;
    }
  }
  .TableContainer {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 16px;
    background-color: white;
    margin-top: 34px;
    padding: 20px;
    box-sizing: border-box;
    .TableHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
    }
  }
}
.ant-tabs-nav-wrap {
  justify-content: flex-start !important;
  .ant-tabs-tab {
    // min-width: 50px !important;
    // justify-content: center !important;
    margin-left: 100px !important;
    &:first-child {
      margin-left: 50px !important;
    }
  }
}
