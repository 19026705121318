.autocomplete-input {
  display: flex;
  position: absolute;
  top: 50px;
  left: 28%;
  transform: translateX(28%);
  z-index: 1;
  background: white;
  padding: 13px 13px 13px 20px;
  border-radius: 5px;
  width: 240px;
}

.react-geocoder-results div {
  padding-top: 5px;
  padding-bottom: 5px;
}

.react-geocoder input {
  background: none;
  font-size: 15px;
  outline: none;
  border: none;
  padding-left: 10px;
}

.iconify--bx {
  margin-top: 2px;
}

.select-location-btn {
  position: absolute;
  top: 53px;
  left: 48%;
  transform: translateX(48%);
  z-index: 1;
}

.select-location-btn button {
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
}

.select-btn {
  margin-bottom: 50px;
  position: absolute;
  bottom: 0;
  right: 20%;
  //   transform: translateX(50%);
  z-index: 1;
}

.not-ready {
  color: #0fc90f;
}

.ready {
  color: #fff;
  background-color: #0fc90f;
}
