@import "../../../../theme/utilities/variable.scss";

.auth {
  .container {
    display: flex;
    height: 100vh;
    width: 100%;
    background: $background-color;

    .right-container {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .left-container {
      padding: 2.6rem;
      box-sizing: border-box;
      align-items: flex-start;
      display: flex;
      width: 50%;
      flex-direction: column;
      padding-left: 8%;
      padding-right: 8%;
      height: 100%;

      .forms {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .appLogo {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
          img {
            // width: 92px;
            height: 40px;
          }
        }
        .input-element {
          margin: 1.4rem 0;

          input {
            height: 2.8rem;
          }
        }

        .action-buttons {
          display: flex;
          justify-content: flex-end;
          // margin-top: 4rem;
        }
      }
    }
  }

  border-color: #57a8e9 !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);

  @media only screen and (max-width: 768px) {
    //tablet screen
    .container {
      flex-direction: column;
      .right-container {
        display: none;
      }
      .left-container {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    //mobile screen
    .container {
      flex-direction: column;
      .right-container {
        display: none;
      }
      .left-container {
        width: 100%;
      }
    }
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(
      -10px
    ); /* Adjust the value for the height of the bounce */
  }
  60% {
    transform: translateY(-5px); /* Adjust for a softer bounce */
  }
}

.auth .right-container img {
  animation: bounce 2s infinite; /* Adjust the duration and iteration as needed */
}
