@font-face {
  font-family: "Futura"; /*Can be any text*/
  src: local("Futura"),
    url("../assets/fonts/futuramedium.ttf") format("truetype"),
    url("../assets/fonts/FuturaHeavy.ttf") format("truetype"),
    url("../assets/fonts/FuturaBold.ttf") format("truetype");
}

:root {
  --absolute-black: #212427;
  --base-color-red: #ff0000;
  --base-color-black: #000929;
  --base-color-gray-light: #f4f6f9;
  --base-color-gray-dark: #7c8091;
  --base-color-green: #73af00;
  --base-color-blue: #108ee9;
}

.ant-table-column-sort {
  background: none !important;
}
.ant-table-column-has-sorters {
  background: #fafafa !important;
}
